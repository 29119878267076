import { useState } from "react";

import { ReleaseVersionsTypes } from "../../../types/information/information";

import Version20230718 from "./Versions/Version20230718";
import Version20230904 from "./Versions/Version20230904";
import Version20230914 from "./Versions/Version20230914";
import Version20231004 from "./Versions/Version20231004";
import Version20231107 from "./Versions/Version20231107";
import Version20231221 from "./Versions/Version20231221";
import Version20240131 from "./Versions/Version20240131";
import Version20240304 from "./Versions/Version20240304";
import Version20240403 from "./Versions/Version20240403";
import Version20240502 from "./Versions/Version20240502";
import Version20240603 from "./Versions/Version20240603";
import Version20240703 from "./Versions/Version20240703";
import Version20240819 from "./Versions/Version20240819";
import Version20240930 from "./Versions/Version20240930";
import Version20241113 from "./Versions/Version20241113";
import Version20241219 from "./Versions/Version20241219";
import Version20250130 from "./Versions/Version20250130";

const ReleaseNotesAccordion = () => {
  const [expanded, setExpanded] = useState<ReleaseVersionsTypes | "">("");

  return (
    <div>
      <Version20241219
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20241113
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240930
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240819
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240703
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240603
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240502
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240403
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240304
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20240131
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20231221
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20231107
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20231004
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20230914
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20230904
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20230718
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
      <Version20250130
        expanded={expanded}
        onChange={(panel) => setExpanded(panel)}
      />
    </div>
  );
};
export default ReleaseNotesAccordion;
